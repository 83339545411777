import '../components/hero-header.less'
import JobBgImage from '../images/jobsbg@2x.png'
import JobCard from '../components/job-card'
import Layout from '../components/layout'
import React, { useState } from 'react'
import _get from 'lodash/get'
import _intersection from 'lodash/intersection'
import _map from 'lodash/map'
import queryString from 'query-string'
import { Item, Picker, Section, ToggleButton } from '@adobe/react-spectrum'
import { Link, graphql } from 'gatsby'
import { isBrowser } from '../utils/isBrowser'

// Filters type declaration
type FiltersType = {
    categories: string[]
    location?: string
    project?: string
}

// Initial filters configuration
const initialFilters: FiltersType = Object.freeze({
    categories: [],
    location: undefined,
    project: undefined,
})

const JobsListPageTemplate: React.FC = props => {
    // Getting data
    const allJobs = _get(props, 'data.jobs.nodes', [])
    const categories = _get(props, 'data.categories.nodes', [])
    const projects = _get(props, 'data.projects.nodes', [])
    const locations = _get(props, 'data.locations.nodes', [])

    /* ***********************************
     * Defining page states
     *************************************/

    const [filters, setFilters] = useState<FiltersType>(() => {
        return {
            ...initialFilters,
            ...(isBrowser() &&
                queryString.parse(window.location.hash, {
                    arrayFormat: 'comma',
                })),
        }
    })

    // Checking if any category is selected
    const hasSelectedCategories = filters.categories.length > 0

    // Checking if location is selected
    const hasSelectedLocation = filters.location && filters.location !== 'any'

    // Checking if project is selected
    const hasSelectedProject = filters.project && filters.project !== 'any'

    // Checking if any filters are set
    const isFiltered =
        hasSelectedCategories || hasSelectedLocation || hasSelectedProject

    // Filtering jobs
    const filteredJobs = allJobs.filter(job => {
        // True if job matches any filter
        let matching = true

        // Checking categories
        if (filters.categories.length) {
            // Getting ids of job categories
            const ids = _map(job.category, 'id')
            // Checking if any category matches
            matching = _intersection(filters.categories, ids).length > 0
        }

        // Checking location
        if (filters.location && filters.location !== 'any') {
            matching = filters.location === job.location?.id
        }

        // Checking project
        if (filters.project && filters.project !== 'any') {
            matching = filters.project === job.project?.id
        }

        return matching
    })

    /* ***********************************
     * Defining page callbacks
     *************************************/

    const setFiltersHash = filters => {
        // If in browser updating address bar
        if (isBrowser()) {
            window.location.hash = queryString.stringify(filters, {
                arrayFormat: 'comma',
            })
        }
    }

    const updateFilters = filters => {
        setFilters(filters)
        setFiltersHash(filters)
    }

    const onResetCategories = () => {
        updateFilters({
            ...filters,
            categories: [],
        })
    }

    const onCategoryChange = (categoryId: string, isSelected) => {
        // Cloning categories
        const categories = [...filters.categories] as string[]

        // Getting index of category
        const idx = categories.indexOf(categoryId)

        if (isSelected) {
            if (idx === -1) {
                categories.push(categoryId)
            }
        } else {
            if (idx >= 0) {
                categories.splice(idx, 1)
            }
        }

        // Updating filters
        updateFilters({
            ...filters,
            categories,
        })
    }

    return (
        <Layout>
            <div
                className="hero_header dark"
                style={{ backgroundImage: `url("${JobBgImage}")` }}
            >
                <div className="breadcrumbs">
                    <Link to="/">Home</Link>
                    <a href="">Jobs</a>
                </div>
                <div className="title">Join us</div>
                <div className="subtitle">
                    Bring your talents to Adobe and help us create the future of
                    digital experiences.
                </div>
            </div>
            <div className="listpage">
                <div className="title">Latest jobs</div>
                <div className="filters columns two">
                    <div className="categories">
                        <ToggleButton
                            key="all_categories"
                            id="reset_categories"
                            defaultSelected={true}
                            isDisabled={!hasSelectedCategories}
                            onPress={onResetCategories}
                        >
                            All Categories
                        </ToggleButton>

                        <span className="separator" />

                        {categories.map(category => (
                            <ToggleButton
                                key={category.id}
                                id={category.id}
                                marginEnd="8px"
                                onChange={value =>
                                    onCategoryChange(category.id, value)
                                }
                                isSelected={filters.categories.includes(
                                    category.id
                                )}
                            >
                                {category.name}
                            </ToggleButton>
                        ))}
                    </div>
                    <div className="others">
                        <Picker
                            marginEnd="8px"
                            aria-label="related projects"
                            placeholder="All Projects"
                            selectedKey={filters.project || ''}
                            onSelectionChange={project =>
                                updateFilters({
                                    ...filters,
                                    project,
                                })
                            }
                        >
                            <Section>
                                <Item key="any">All Projects</Item>
                            </Section>
                            <Section>
                                {projects.map(project => (
                                    <Item key={project.id}>
                                        {project.defaultTitle}
                                    </Item>
                                ))}
                            </Section>
                        </Picker>
                        <Picker
                            id="location_selector"
                            aria-label="Location"
                            placeholder="All Locations"
                            selectedKey={filters.location || ''}
                            onSelectionChange={location =>
                                updateFilters({
                                    ...filters,
                                    location,
                                })
                            }
                        >
                            <Section>
                                <Item key="any">All Locations</Item>
                            </Section>
                            <Section>
                                {locations.map(location => (
                                    <Item key={location.id}>
                                        {location.city}
                                    </Item>
                                ))}
                            </Section>
                        </Picker>
                    </div>
                </div>

                {isFiltered && (
                    <div key="initialfiltering" className="isFiltered">
                        You&apos;re viewing a filtered version of this list.{' '}
                        <a
                            href="#"
                            onClick={() => updateFilters(initialFilters)}
                        >
                            Reset filters
                        </a>
                    </div>
                )}

                <div className="content">
                    {filteredJobs.length > 0 ? (
                        <div className="columns two gap32">
                            {filteredJobs.map(job => (
                                <div key={job.id}>
                                    <JobCard
                                        key={job.id}
                                        title={job.title}
                                        description={job.shortDescription}
                                        location={job.location.city}
                                        date={job.date}
                                        category={job.category}
                                        id={job.id}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="empty">
                            There are no jobs available matching your criteria.
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default JobsListPageTemplate

export const pageQuery = graphql`
    query {
        jobs: allCmsJob(limit: 100) {
            nodes {
                id
                shortDescription
                title
                location {
                    city
                    id
                }
                date
                category {
                    name
                    id
                }
                project {
                    id
                }
            }
        }
        projects: allCmsProject {
            nodes {
                id
                slug
                defaultTitle
            }
        }
        categories: allCmsCategory {
            nodes {
                id
                name
            }
        }
        locations: allCmsJobLocation {
            nodes {
                id
                city
            }
        }
    }
`
