import './job-card.less'
import React from 'react'
import { Link } from 'gatsby'
import { parseDate } from '../utils/parseDate'

interface JobCardProps {
    id: string
    title: string
    description: string
    location: string
    date: string
    category: any[]
}

const JobCard: React.FC<JobCardProps> = (props: JobCardProps) => {
    const date = parseDate(props.date).format('MMM D, Y')
    return (
        <Link className="jobCard" to={'/jobs/' + props.id}>
            <div className="title">{props.title}</div>
            <div className="categories">
                {props.category.map(cat => (
                    <span key={cat.id} className="cat">
                        {cat.name}
                    </span>
                ))}
            </div>
            <div className="description">{props.description}</div>
            <div className="location">{props.location}</div>
            <div className="date">{date}</div>
        </Link>
    )
}

export default JobCard
